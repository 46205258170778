<template>
  <header
    class="h-header py-4 px-3 lg:px-12 z-20 flex justify-between
      bg-white border-b border-ash-600 border-opacity-20"
  >
    <!-- logo -->
    <div class="h-full cursor-pointer flex-1">
      <DgImg
        @click.native="methodGoHome"
        contain
        src="logo/imgLogo"
        class="h-full"
        position="0 50%"
      />
    </div>
    <!-- menu -->
    <div
      class="justify-end flex items-stretch flex-1"
    >
      <Login
      />
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import DgImg from '@/components/base/DgImg.vue';
import Login from '@/components/app/header/Login.vue';

const select = [
  {
    title: '全部',
    value: null,
  },
  {
    title: '內容1',
    value: 1,
  },
];

export default {
  name: 'Header',
  components: {
    DgImg,
    Login,
  },
  data() {
    return {
      select,
      isMemberMenuHover: false,
      isNotificationHover: false,
    };
  },
  computed: {
    ...mapState(['isLogin']),
  },
  methods: {

    methodGoHome() {
      window.location.href = 'https://www.digiwin.com/tw';
      // this.$router.push({
      //   name: 'Home',
      // });
    },
  },
};
</script>
