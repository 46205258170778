<template>
  <div class="flex flex-row items-center">
    <router-link
      v-if="!isLogin"
      to="/login"
      tag="div"
      class="
        bg-primary-gradient-to-r p-1 rounded-full cursor-pointer
        grid grid-flow-col items-center
      "
    >
      <!-- h-10 w-24 -->
      <p
        class="text-base text-white px-3"
      >登入</p>
      <DgIcon
        size="30"
        name="user-circle"
        color="white"
        class=""
      />
    </router-link>

    <div
      v-else
      class="cursor-pointer"
      @click="logout"
    >
      <p
        class="text-base text-ash-400 px-3"
      >登出</p>

    </div>

    <router-link
      v-if="!isLogin"
      to="/signup"
      class="ml-4"
    >
      <p
        class="text-base text-ash-400 "
      >註冊</p>
    </router-link>

    <router-link
      v-else
      to="/"
      class="pl-4 flex items-center border-l border-ash-400"
    >
      <DgIcon
        size="17"
        name="home"
        color="ash-400"
        class="mr-2"
      />
      <p
        class="text-base text-ash-400 cursor-pointer"
      >返回下載列表</p>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { checkStorage } from '@/utils/timerStorage';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Login',
  components: {
    DgIcon,
  },
  data() {
    return {
      ifToken: false,
    };
  },
  computed: {
    ...mapState(['isLogin']),
  },
  watch: {
    // isLogin(val, old) {
    //   console.info('👉👉 val, old', val, old);
    // },
  },
  methods: {
    ...mapActions({
      actionLogout: 'logout',
    }),
    ...mapMutations({
      showNotifySuccess: 'setNotifySuccess',
      showNotifyError: 'setNotifyError',
    }),
    checkStorage,
    logout() {
      this.actionLogout();
      this.ifToken = checkStorage('token');
      this.$router.go(0);
      // this.$router.push({ name: 'Index' });
    },
  },
  mounted() {
    this.ifToken = checkStorage('token');
  },
};
</script>
