<template>
  <footer
    class="h-footer border-t border-ash-600 border-opacity-20 flex justify-center items-center"
  >
    <p class="text-xs text-ash-300 text-center">
      Copyright © 2021 Data Systems Consulting Co.,Ltd. All rights reserved.
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
