<template>
  <div class="w-full h-screen overflow-auto overflow-x-hidden relative shadow flex flex-col">
    <Header
      class="sticky top-0"
    />
    <router-view
      class="hash-content flex-grow"
    />
    <Footer class="flex-shrink-0" />
  </div>
</template>

<script>
import Header from '@/components/app/header/Index.vue';
import Footer from '@/components/app/Footer.vue';

export default {
  name: 'IndexLayout',
  components: {
    Header,
    Footer,
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
